@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap-variables";


@mixin material-box-shadow($level) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}





.width-auto {
  width: auto;
}


.font-size-base {
  font-size: $font-size-base;
}

.font-size-small {
  font-size: $font-size-sm;
}

.font-size-xsmall {
  font-size: $font-size-xs;
}

.color-secondary {
  color: $color2-text;
}

