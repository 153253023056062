.blog-content-container {
  position: relative;
  padding-top: $spacer;
  overflow: auto;
  background: $color2-dividers;

  .blog-hunk-container {
    margin-bottom: ($spacer * 3);
    padding: 0;

    @include material-box-shadow(1);
    background: #fff;

  }

}


  .navbar {
  transition: height 0.5s ease;

  .navbar-brand {

    img {
      width: (4 * $spacer);  // we want this to be proportional to the base font-size (i.e. $spacer)
      height: (4 * $spacer);
      transition: all 0.3s ease;
    }

    .brand-text {
      margin-left: $spacer;
      color: $color1-text;

      .name {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
      }

      .tagline {
        font-size: $font-size-xs;
        font-weight: $font-weight-light;
        color: $color2-text;
      }
    }
  }


  .navbar-nav {
    .nav-item {
      padding-left: $spacer * 0.75;
      padding-right: $spacer * 0.75;

      &:last-child {
        padding-right: 0;
      }

      &.nav-link {
        font-weight: $font-weight-bold;
        color: $color1-text;
        transition: all 0.2s ease;

        &:hover, &.active {
          color: $color2-links;
          text-decoration: underline;
        }
      }
    }

  }



  &.navbar--sticky  {
    @extend .fixed-top;
    //border-bottom: 1px solid $color2-lines;
    padding: ($spacer / 2);
    @include material-box-shadow(1);
    transition: height 0.5s ease;

    .navbar-brand {
      img {
        width: (2 * $spacer); // we want this to be proportional to the base font-size (i.e. $spacer)
        height: (2 * $spacer);
        transition: all 0.3s ease;
      }

      .brand-text {
        margin-left: $spacer;

        .name {
          font-size: $font-size-lg;
          font-weight: $font-weight-bold;
        }

        .tagline {
          display: none;
        }
      }
    }
  }

}


.container--with-navbar-sticky-spacer {
  padding-top: 0;

  .navbar--sticky + & {
    padding-top: (2 * ($navbar-brand-padding-y * 2 + $navbar-brand-height)) + $spacer; // 2x the height of the navbar + extra spacing
  }
}



.page-section {
  padding: 0 0 (4 * $spacer)  0;


  &.page-section--with-border-top {
    border-top: 1px solid $color2-lines;
  }

  &.page-section--light-blue {
    background: $color2-background-info;
  }
}



.page-section.footer {
  h4 {
    margin-bottom: $spacer;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  h5 {
    margin-bottom: $spacer/2;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }

  p {
    margin-bottom: ($spacer/2);
    font-size: $font-size-base;
  }

  address {
    font-size: $font-size-sm;
  }

}




.page-section.basement {
  padding: (2 * $spacer) 0;

  font-size: $font-size-xs;
  text-align: center;
  color: $color2-text;

  .basement__copyright-notice {
  }

  .basement__bottom-links {
  }
}
