


.post-list {
  padding-top: $spacer;


  .post-item {
    padding-top: $spacer;
    padding-bottom: $spacer;

    .post-meta {
      font-size: $font-size-sm;
      color: $color2-text;
    }

    .post-title {
      font-size: $font-size-lg;

      @include media-breakpoint-up(xs) {
        font-size: $font-size-sm;
      }
      @include media-breakpoint-up(sm) {
        font-size: $font-size-lg;
      }
    }

    .post-excerpt {
      padding-top: $spacer/2;
    }

  }
}







.post {
  .post-header {

    .post-title {
      @include media-breakpoint-up(xs) {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }
      @include media-breakpoint-up(sm) {
        font-size: $font-size-xl;
      }
    }

    .post-meta {
      color: $color2-text;
      font-size: $font-size-sm;
      font-weight: $font-weight-light;

      .dt-published {
        font-weight: $font-weight-normal;
      }

      .post-authors {
        .author {
          font-weight: $font-weight-normal;
        }
      }
    }
  }


  .post-content {
    padding-top: $spacer;

    @include media-breakpoint-up(xs) {
      font-size: $font-size-sm;
    }
    @include media-breakpoint-up(sm) {
      font-size: $font-size-base;
    }

    img,
    .image--full-width {
        @extend .img-fluid;
    }
  }

}



